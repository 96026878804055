import React from 'react'
import { css } from '@emotion/core'
import Layout from '../components/layout'
import { fontSizes } from 'utils/typography'
import SecondaryParagraph from 'components/text/secondary-paragraph'

import AddToSlackButton from 'components/buttons/add-to-slack'

const Faq = () => {
  return (
    <Layout>
      <div>
        <h1>/toast ask</h1>
        <p
          css={css`
            color: #333;
          `}
        >
          We address all <code>/toast ask</code>s. Here are some common asks.
        </p>
        <h4>Why is it called Toast?</h4>
        <p
          css={css`
            font-size: ${fontSizes.small1};
          `}
        >
          Toast is all at once a homage to the infamous avocado toast frequently
          seen in the Bay, and also a shoutout to positivity and pride in a job
          well done. We believe feats of engineering should be celebrated, not a
          source of headache. Toast aims to reduce engineering overhead, and
          allow you to focus on building, because building is fun. Leave the
          follow-up to Toast. And when you merge, it's a victory. So have a
          Toast on us. Celebrate.
        </p>
        <h4>Why does Review Toast have sad eggy eyes?</h4>
        <p
          css={css`
            font-size: ${fontSizes.small1};
          `}
        >
          Frequently seen in Slack channels:
          <br /> <code>My PR needs eyes! [GitHub link]</code> <br />
          Review Toast embodies this concept and politely requests the attention
          of his reviewer with puppy eyes in the form of sunny-side ups.
        </p>
        <div>
          <AddToSlackButton size={60} />
          <SecondaryParagraph>
            Toast cannot read your Slack messages.
          </SecondaryParagraph>
        </div>
      </div>
    </Layout>
  )
}

export default Faq
